import { useWeb3React } from '@web3-react/core';
import { connectWallet } from "../utils/connectWallet";

import { Link, NavLink } from 'react-router-dom'
import '../assets/css/header.css'
import imageURL from '../hooks/imageUrl'
function Header(props) {
    const {
        active,
        activate,
        account,
    } = useWeb3React();
    const truncAddress = (str, n = 6) => {
        if (str) {
            return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
        }
        return '';
    };
    return <>
        <header className='custom-header'>
            <div className='header-body'>
                <div className='logo-section'>
                    <img src={imageURL('logo.png')} className="logo" />
                </div>
                
            </div>
            <div className='connect-section'>
                {
                    active?<button className='custom-btn primary-btn' >{truncAddress(account,4)}</button>:
                    <button className='custom-btn primary-btn' onClick={() => {
                        connectWallet(activate, props.setErrorMessage);
                    }}>Connect Wallet</button>
                }
                
            </div>
        </header>
    </>
}

export default Header