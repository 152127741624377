import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";
import { connectWallet } from "../utils/connectWallet";

function Home(props) {
  const {
    active,
    activate,
  } = useWeb3React();
  const [proposals, setProposals] = useState(
    [
      { name: 'Introduce Cross chain asset lening and collateral', date: 'Starts in 79 Days', "yes": 0, "no": 0, 'status': "upcoming" },
      { name: 'Add Dogechain assets to the ecosystem', date: 'Starts in 106 Days', "yes": 0, "no": 0, 'status': "upcoming" },
      { name: 'Should we add Safemoon to the market?', date: 'Starts in 136 Days', "yes": 0, "no": 0, 'status': "upcoming" },
      { name: 'Add Harmony Chain to ecosystem', date: 'Starts in 183 Days', "yes": 0, "no": 0, 'status': "upcoming" }
    ]
  );
  return (
    <main>
      <section className="section-first">
        <Container fluid className="custom-container">
          <div className="first-section-main">
            <button className="home-dropdown-btn">
              <img src={imageURL('unvt.png')} />
              <span>Univaults Governance</span>
              <i class="fa-solid fa-chevron-down"></i>
            </button>
            <p className="normal-text text-white">
              Univaults is a fully decentralized and community governed protocol that is controlled by UNVT token-holders. UNVT holders collectively discuss, propose, and vote on upgrades to the protocol.
            </p>
            <a href="#" className="custom-btn-sm primary-btn" target="_blank" ><span>SNAPSHOTS</span><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          </div>
        </Container>
      </section>
      <section className="second-section">
        {

          <Row>
            <Col lg={4}>
              <div className="box-main box-top-minus gradient-border">
                <h4 className="sm-title">Your info</h4>
                <div className="box-footer">
                  <span>Enter the Governance</span>
                  {
                    active?
                    <button className='custom-btn secondary-btn' disabled>Delegate</button>
                    :
                    <button className='custom-btn primary-btn' onClick={() => {
                      connectWallet(activate, props.setErrorMessage);
                  }}>Connect Wallet</button>
                  }
                  
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="box-main box-top-minus m-top-none">
                <div className="filter-flex">
                  <h4 className="sm-title">Proposals</h4>
                  <div className="filter-main">
                    <span className="gray-text">Filter</span>
                    <Form.Select >
                      <option value="">All Proposals</option>
                    </Form.Select>
                  </div>
                </div>
                <ul className="proposals-list">
                  {
                    proposals.map((pro)=>{
                      return    <li>
                      <div className="title-info">
                        <h4>{pro.name}</h4>
                        <div className="title-footer">
                          <div className="status danger inner">
                            <i class="fa-solid fa-circle"></i>
                            <span>{pro.status}</span>
                          </div>
                          <div className="inner">
                            <span>{pro.date}</span>
                          </div>
                        </div>
                      </div>
                      <div className="progress-main">
                        <div className="progress-inner">
                          <div className="d-flex-space">
                            <span>Yes</span>
                            <span>{pro.yes}</span>
                          </div>
                          <div className="progress-bar">
                            <div className="bar" style={{ width: '0%' }}>
  
                            </div>
                          </div>
                        </div>
                        <div className="progress-inner mt-2">
                          <div className="d-flex-space">
                            <span>No</span>
                            <span>{pro.no}</span>
                          </div>
                          <div className="progress-bar">
                            <div className="bar" style={{ width: '0%' }}>
  
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    })
                  }
               
                </ul>
              </div>
            </Col>
          </Row>

        }

      </section>
    </main>
  );
}

export default Home;
